// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-plugin-cart-redirect-src-cart-redirect-page-tsx": () => import("./../../../../gatsby-plugin-cart-redirect/src/CartRedirectPage.tsx" /* webpackChunkName: "component---gatsby-plugin-cart-redirect-src-cart-redirect-page-tsx" */),
  "component---gatsby-plugin-index-src-404-404-tsx": () => import("./../../../../gatsby-plugin-index/src/404/404.tsx" /* webpackChunkName: "component---gatsby-plugin-index-src-404-404-tsx" */),
  "component---gatsby-plugin-index-src-index-index-tsx": () => import("./../../../../gatsby-plugin-index/src/index/Index.tsx" /* webpackChunkName: "component---gatsby-plugin-index-src-index-index-tsx" */),
  "component---gatsby-plugin-index-src-page-index-page-index-tsx": () => import("./../../../../gatsby-plugin-index/src/page-index/PageIndex.tsx" /* webpackChunkName: "component---gatsby-plugin-index-src-page-index-page-index-tsx" */)
}

